<template>
  <div class="query-box">
    <app-container :title="'卡片充值'" :background="tool.getThemeList(theme)">
      <div class="query-home">
        <ad-banner ref="adBanner" :_platformCode="platformCode"></ad-banner>
        <div class="cardNum">
          <input
            type="text"
            @focus="inputFocus = true"
            @blur="close"
            placeholder="请输入ICCID/SIM/绑定手机号码"
            v-model="iccid"
            oninput="this.value = this.value.slice(0,20)"
            id="iccid"
          />
          <div class="iconWrap">
            <van-icon
              name="clear"
              class="cancel"
              @click="iccid = ''"
              v-if="iccid.length > 0"
            />
            <img
              class="icon"
              src="../../assets/image/query/card.png"
              v-if="!scanShow"
            />
            <img
              class="icon"
              src="../../assets/image/query/scan.png"
              @click="scanHandle"
              v-else
            />
          </div>
          <input-history
            :dataList="dataList"
            :iccid="iccid"
            @setData="setData"
            v-show="inputFocus"
            v-if="
              dataList.length >= 1 &&
                JSON.stringify(dataList).indexOf(iccid) > -1
            "
          ></input-history>
        </div>    
        <!-- theme == 'AnFang' &&  -->
        <div v-if="dataList.length >= 1">
            <div style="margin: 10px 16px">历史搜索</div>
            <div class="historyList" v-for="(item, index) in JSON.parse(JSON.stringify(dataList))" :key="index" @click="setData(item)">
                <div>
                    <van-icon name="clock-o" />                    
                    <span style="padding-left: 12px;">{{ item }}</span>
                </div>
                <div @click.stop="clearItem(item)">
                    <van-icon name="cross" />
                </div>
            </div>
        </div>
        <button class="submit" :disabled="!iccid.length" @click="submit()">
          查询
        </button>
      </div>
      <scan-type ref="actionNotice" @scanCB="parseCode"></scan-type>
    </app-container>
    <p class="version">
      <span @click="consoleFn">V:{{ version }}-{{ device }}</span>
    </p>
    <!-- 优惠券弹窗 -->
    <af-change-card ref="afChangeCard" @loginHandler="loginHandler"></af-change-card>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, toRefs, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import { setStore, getStore, removeStore } from '@/utils/store'
import { isDevice } from '@/utils/utils'
import { getconf, testCard, advertLog, getVersion, getAliUserId, queryCardSignContract } from '_API_/api.services'
import { trackSubmit, handleToLowerCase } from '@/utils/track'
import jssdk from '@/assets/js/jssdk.js'
import { Dialog, Toast } from 'vant'
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  setup () {
    const state = reactive({
      authCode: '',
      tempParams: getStore({ name: 'tempParams' }) || '',
      openId: '',
      iccid: '',
      card: '',
      platformCode: '', // 平台code 码
      scanShow: false, // 展示查询方式的icon
      inputFocus: false,
      jssdkConf: {}, // jssdk配置信息
      device: '', // 用户设备信息
      dataList: [], // 检测通过的卡列表
      theme: '', // 主题名称
      version: '',
      num: 0,
      Vconsole: null,
      isSimple: 0 // 0 常规版 、 1 简版
    })
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance()
    // 路由 this.$router
    const { $http: http, $router: router, $tool: tool, $store: store } = ctx
    // 调用useRoute,获取页面携带的参数
    const route = useRoute()

    // 方法
    const methods = {
      loginHandler (iccid) {
        state.iccid = iccid
        methods.submit()
      },
      goUrl () {
        location.href = 'http://dialog.iot.xiaoyizuji.com/'
      },
      consoleFn () {
        state.num += 1
        if (state.num >= 8) {
          state.Vconsole = new window.VConsole()
        }
      },
      // 点击关闭光标
      close () {
        setTimeout(() => {
          state.inputFocus = false
        }, 100)
      },
      // 接收子组件传递的值
      setData (item) {
        state.iccid = item
      },
      // 清除历史记录
      clearItem(param){              
        const itemList = JSON.parse(JSON.stringify(state.dataList))
        const needSaveList = itemList.filter(item => {
          return item != param
        })        
        // 更新数据
        state.dataList = needSaveList
        store.dispatch(
          'SetUserList',
          JSON.parse(JSON.stringify(needSaveList))
        )        
      },  
      // 保存输入记录
      saveIccid (iccid) {
        if (state.dataList.indexOf(iccid) > -1) {
          return
        }
        if (state.dataList.length > 10) {
          state.dataList.pop()
        }
        // 将输入过的卡号存进dataList
        state.dataList.push(iccid)
        store.dispatch(
          'SetUserList',
          JSON.parse(JSON.stringify(state.dataList))
        )
      },
      // 扫码
      scanHandle () {
        switch (state.device) {
          case 'wx':
            jssdk.wxScan().then(res => {
              ctx.parseCode(res)
            })
            break
          case 'ali':
            nextTick(() => {
              ctx.$refs.actionNotice.isShow()
            })
            break
          default:
            tool.toast({
              msg: '当前环境不支持扫码识别卡号'
            })
            break
        }
      },
      // 卡号查询的跳转 type: 0-点击登录  1-自动登录
      submit (type = 0) {
        console.log('submit')
        const conf = {}
        if (state.iccid.length != 11) {
          conf.card = state.iccid.replace(/(^\s*)|(\s*$)/g, '') // 消除前后空格
          conf.device = state.device
        } else {
          conf.mobile = state.iccid.replace(/(^\s*)|(\s*$)/g, '') // 消除前后空格
          conf.device = state.device
        }
        // 检测卡
        testCard(conf).then((data) => {
          if (conf.card && conf.card.length == 20 && data.data.code == 200108 && data.data.msg == '卡不存在,请核对您的卡号' && state.theme === 'AnFang') {
            console.log('--------')
            state.iccid = state.iccid.slice(0, 19)
            return methods.submit()
          }
          // 手机号 - 会查询该手机号是否有多张卡片
          const cardList = data.data.cardList
          // const cardList = [
          //   {operators: 1, iccid: '8986032641200561221'},
          //   {operators: 2, iccid: '8986032641200561222'},
          //   {operators: 3, iccid: '8986032641200561227'},
          //   {operators: 4, iccid: '8986032641200561228'},
          //   {operators: 2, iccid: '8986032641200561229'},
          // ]
          // 没有改字段 调用登录则直接登录
          if (!cardList) {
            return methods.checkCardCallBack(data, type)
          }
          if (cardList.length == 0) {
            return tool.toast({
              msg: '当前无绑定卡片！',
              duration: 1500
            })
          }
          if (cardList.length == 1) {
            // 仅一张 - 取值 重新进行 登录调用
            state.iccid = cardList[0].iccid
            console.log('取当前卡 - 调用登录', state.iccid)
            return methods.submit()
          } else if (cardList.length > 1) {  
            // 弹窗 结束
            return ctx.$nextTick(() => {
              ctx.$refs.afChangeCard.init(cardList)
            })
          }
          methods.checkCardCallBack(data, type)
        })
      },
      async checkCardCallBack ({ data: res }, isAutoSkip) {
        trackSubmit({
          isAutoSkip,
          eventCode: handleToLowerCase(state.theme) + '_card_query_chick',
          content: state.iccid,
          isSuccess: res.code == 0 ? 1 : 0,
          errMsg: res.code != 0 ? res.msg : undefined,
          no_params: true
        })
        if (['YuYin', 'AnFang'].includes(state.theme) && state.device === 'wx') {
          setStore({
            name: 'tempParams',
            content: res,
            type: 'session'
          })
          return methods.judgeOpenId(res)
        }
        if (['YuYin', 'AnFang'].includes(state.theme) && state.device === 'ali') {
          setStore({
            name: 'tempParams',
            content: res,
            type: 'session'
          })
          return methods.judgeAliOpenId(res)
        }
        setStore({
          name: 'shopUrl',
          content: res.shopUrl || '',
          type: 'session'
        })
        if (res.code == 0 || res.code == 201) {
          store.dispatch('SetUserInfo', {
            appid: res.AppID || '',
            industry: res.Platform == 1 ? 'zx' : 'xm',
            payType: res.payType
          })
        }
        if (res.hasOwnProperty('redirectUrl') && res.redirectUrl) {
          // 从sessionStorage删除plateForm标识
          removeStore({ name: 'plateForm', type: 'session' })
          const str = 'cloud.aiotzy.com'
          if (res.redirectUrl.indexOf(str) != -1) {
            window.location.href = res.redirectUrl + state.iccid
          } else {
            window.location.href = res.redirectUrl + '?iccid=' + state.iccid
          }
          return
        }
        if (res.code == 0) {
          const { data } = await ctx.getSignContract(res.iccid)
          if (data && data.code == 0) {
            const isSignContract = data.data.isSignContract
            // 保存签约状态
            setStore({
              name: 'contractStatus',
              content: isSignContract,
              type: 'session'
            })
          }
          store.dispatch('SetUserInfo', {
            iccid: res.iccid,
            virtualId: res.virtualId
          })
          // 保存输入框的输入记录
          methods.saveIccid(state.iccid)
          // 从sessionStorage删除plateForm标识
          removeStore({ name: 'plateForm', type: 'session' })
          // 是否直接跳转充值
          const url = res.hdUrl ? res.hdUrl : ''
          // 保存hdUrl标识到sessionStorage
          setStore({
            name: 'hdUrl',
            content: url,
            type: 'session'
          })
          if (url) {
            advertLog({
              param: res.iccid,
              type: 1,
              browserType: state.device
            }).then(({ data: res }) => {})
          }
          // 点击查询卡号成功，保存loginAccessToken到sessionStorage
          store.dispatch('SetLoginToken', state.iccid)
          // 路由跳转到个人中心
          router.push({
            name: 'personal'
          })
          return
        }
        if (res.code == 201) {
          // 部分卡片需强制绑定手机号
          tool.toast(
            {
              msg: '当前卡片未绑定用户',
              duration: 1500
            },
            () => {
              router.push({
                name: 'personal-bindMobile',
                query: {
                  iccid: res.iccid,
                  virtualId: res.virtualId,
                  isExchangeCode: res.isExchangeCode || ''
                }
              })
            }
          )
          return
        }

        tool.alert(
          {
            title: '提示',
            msg: res.msg
          },
          () => {}
        )
      },
      parseCode (res) {
        const _res = res.includes('card')
          ? res.slice(res.indexOf('card=') + 6) || ''
          : res
        state.iccid = _res.substr(_res.indexOf(',') + 1)
        methods.submit()
      },
      getVersion () {
        getVersion().then(({ data }) => {
          if (data.code == 0) {
            state.version = data.v
            state.platformCode = data.platformCode
            store.dispatch('SetPlatformCode', data.platformCode)
            setStore({
              name: 'isCash',
              content: data.shieldRecharge,
              type: 'session'
            })
          }
        })
      },
      judgeOpenId (res) {
        console.log('checkCard检测微信OpenId')
        if ([0, 201].includes(res.code) && ['YuYin', 'AnFang'].includes(state.theme) && state.device === 'wx') {
          location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${res.AppID}&redirect_uri=${location.origin}&response_type=code&scope=snsapi_base&state=STATE&connect_redirect=1#wechat_redirect`
          return
        }
        methods.jumpToNewPage(res)
      },
      judgeAliOpenId (res) {
        console.log('checkCard检测支付宝OpenId')
        if ([0, 201].includes(res.code) && ['YuYin', 'AnFang'].includes(state.theme) && state.device === 'ali') {
          location.href = `https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=${res.aliAppId}&scope=auth_base&redirect_uri=${location.origin}`
          return
        }
        methods.jumpToNewPage(res)
      },
      jumpToNewPage (res) {
        console.log({ res })
        setStore({
          name: 'shopUrl',
          content: res.shopUrl || '',
          type: 'session'
        })
        if (res.code == 0 || res.code == 201) {
          store.dispatch('SetUserInfo', {
            appid: res.AppID || '',
            industry: res.Platform == 1 ? 'zx' : 'xm',
            payType: res.payType
          })
        }
        if (res.hasOwnProperty('redirectUrl') && res.redirectUrl) {
          // 从sessionStorage删除plateForm标识
          removeStore({ name: 'plateForm', type: 'session' })
          const str = 'cloud.aiotzy.com'
          if (res.redirectUrl.indexOf(str) != -1) {
            window.location.href = res.redirectUrl + state.iccid
          } else {
            window.location.href = res.redirectUrl + '?iccid=' + state.iccid
          }
          return
        }
        if (res.code == 0) {
          store.dispatch('SetUserInfo', {
            iccid: res.iccid,
            virtualId: res.virtualId
          })
          // 保存输入框的输入记录
          methods.saveIccid(res.iccid)
          // 从sessionStorage删除plateForm标识
          removeStore({ name: 'plateForm', type: 'session' })
          // 是否直接跳转充值
          const url = res.hdUrl ? res.hdUrl : ''
          // 保存hdUrl标识到sessionStorage
          setStore({
            name: 'hdUrl',
            content: url,
            type: 'session'
          })
          if (url) {
            advertLog({
              param: res.iccid,
              type: 1,
              browserType: state.device
            }).then(({ data: res }) => {})
          }
          // 点击查询卡号成功，保存loginAccessToken到sessionStorage
          store.dispatch('SetLoginToken', res.iccid)
          // 语音平台，跳转到新的个人中心
          if (state.theme === 'YuYin') {
            router.push({
              name: 'yy-platfrom'
            })
          }
          // 安防平台，跳转到新的个人中心
          if (state.theme === 'AnFang') {
            router.push({
              name: 'af-platfrom'
            })
          }
          return
        }
        if (res.code == 201) {
          // 部分卡片需强制绑定手机号
          tool.toast(
            {
              msg: '当前卡片未绑定用户',
              duration: 1500
            },
            () => {
              router.push({
                name: 'personal-bindMobile',
                query: {
                  iccid: res.iccid,
                  virtualId: res.virtualId,
                  isExchangeCode: res.isExchangeCode || ''
                }
              })
            }
          )
          return
        }

        tool.alert(
          {
            title: '提示',
            msg: res.msg
          },
          () => {}
        )
      },
      getOpenId (params) {
        return http.post('/pay/getOpenId', {
          code: params.code || 0,
          appId: params.appId
        }).then(({ data }) => {
          if (data.code == 0) {
            state.openId = data.openId
            // 存储openId到sessionStorage
            setStore({
              name: 'userOpenId',
              content: {
                openId: state.openId || '0'
              },
              type: 'session'
            })
            removeStore({ name: 'tempParams', type: 'session' })
            params.code != 0 && methods.jumpToNewPage(state.tempParams)
          }
        })
      },
      async getAliOpenId (params) {
        const { data } = await getAliUserId({
          accessToken: route.query.auth_code,
          appId: params.aliAppId
        })
        if (data.code == 0) {
          state.openId = data.userId
          // 存储openId到sessionStorage
          setStore({
            name: 'userOpenId',
            content: {
              openId: state.openId || '0'
            },
            type: 'session'
          })
          removeStore({ name: 'tempParams', type: 'session' })
          Boolean(route.query.auth_code) && methods.jumpToNewPage(state.tempParams)
        }
      },
      getSignContract (iccid) {
        return new Promise((resolve, reject) => {
          const data = queryCardSignContract(iccid)
          resolve(data)
        })
      }
    }
    onMounted(() => {
      state.authCode = route.query.code ? route.query.code : ''
      // 检测用户设备
      state.device = isDevice()
      // 获取版本号
      methods.getVersion()
      // 获取主题名称
      state.theme = store.state.common.theme
      // 获取微信openId
      if (['YuYin', 'AnFang'].includes(state.theme) && state.device === 'wx') {
        methods.getOpenId({ code: state.authCode, appId: state.tempParams ? state.tempParams.AppID : '' })
      }
      // 获取支付宝openId
      if (['YuYin', 'AnFang'].includes(state.theme) && state.device === 'ali') {
        methods.getAliOpenId({ aliAppId: state.tempParams ? state.tempParams.aliAppId : '' })
      }
      // 获取存储的输入记录
      state.dataList = store.state.user.userList
      // 检测是否为 simple 版
      if ((route.query.hasOwnProperty('simple') && route.query.simple) || getStore('isSimple')) {
        state.isSimple = Number(route.query.simple)
        store.commit('Set_Item_Type', route.query.simple)
      }
      trackSubmit({ eventCode: handleToLowerCase(state.theme) + '_login_page', no_params: true })

      // 通联支付
      const tongLianInfo = getStore({ name: 'tongLianInfo' })
      store.commit('Set_TongLian_Info', { isTongLian: false })
      // 查询前的检测
      if (
        (route.query.hasOwnProperty('iccid') &&
        Boolean(route.query.iccid) &&
        !getStore({ name: 'plateForm' })) || (tongLianInfo && tongLianInfo.isTongLian)
      ) {
        state.iccid = route.query.iccid || tongLianInfo.card
        ctx.submit(1)
        // 保存plateForm标识到sessionStorage
        setStore({ name: 'plateForm', content: 1, type: 'session' })
      }
      switch (state.device) {
        case 'wx':
          try {
            // 初始化jssdk的config
            getconf({
              url: window.location.href
            }).then(({ data: res }) => {
              if (res.code == 0 && !res.data.code) {
                state.jssdkConf = res.data
                jssdk.config(res.data)
                state.scanShow = true
              }
            })
          } catch (e) {}
          break
        case 'ali':
          state.scanShow = true
          break
        default:
          state.scanShow = false
          break
      }
    })
    return {
      ...toRefs(state),
      ...methods,
      tool
    }
  }
}
</script>

<style lang="scss" scoped>

@import "@/style/theme/index";
.query-box {
  height: 100vh;
  .query-home {
    height: calc(100vh - 92px);
    overflow-y: auto;
    overflow-x: hidden;
    .cardNum {
      width: 660px;
      line-height: 85px;
      height: 85px;
      margin: 40px auto 0px;
      padding: 0 3%;
      position: relative;
      background: #ffffff;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .cardNum .iconWrap {
      display: flex;
      align-items: center;
    }
    .cardNum img.icon {
      height: 40px;
    }

    .cardNum .cancel {
      font-size: 36px;
      color: #d2d2d2;
      margin-right: 15px;
    }

    .cardNum input {
      width: 80%;
      text-indent: 10px;
      font-size: 30px;
      border: none;
      background: transparent;
      color: black;
      height: 100%;
    }

    .cardNum .inputHistory {
      position: absolute;
      background: #fff;
      box-shadow: 0 0 1px 1px #c0c0c0;
      width: 85%;
      top: 83%;
      left: 2%;
      border-radius: 20px;
      max-height: 500px;
      overflow-y: auto;
    }
    .submit {
      width: 700px;
      height: 84px;
      color: #ffffff;
      @include background_color("background_color1");
      // box-shadow: 0px 8px 16px 0px #bce1ff;
      border-radius: 20px;
      border: none;
      margin: 48px 50px 48px 26px;
    }
    .submit[disabled] {
      @include background_color("background_color2");
    }
  }
}
.version {
  position: fixed;
  bottom: 0;
  width: 100vw;
  text-align: center;
  left: 0;
  font-size: 14px;
}

/deep/.van-nav-bar::after {
  border-bottom-width: 0px !important;
}

.historyList{
    display: flex;
    justify-content: space-between;
    padding: 8px 32px;
}
</style>
